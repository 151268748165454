/** @jsx jsx */
import { createContext, FC, memo, ReactNode, useContext } from "react";
import { jsx } from "theme-ui";
import { useTina } from "tinacms/dist/react";

import { PageQuery } from "../../.tina/__generated__/types";
import { PageDocument } from "../../.tina/__generated__/types";
import { widgetPreview } from "./_widgets/preview";

type Context = {
  relativePath: string;
};

export const TinaCMSDataContext = createContext<Context>({
  relativePath: "",
});

export const TinaCMSDataProvider: FC<{
  children: ReactNode;
  relativePath: string;
}> = memo(({ children, relativePath }) => {
  const resolvedRelativePath =
    relativePath || global.localStorage?.getItem("tinaCMSRelativePath") || "";

  return (
    <TinaCMSDataContext.Provider value={{ relativePath: resolvedRelativePath }}>
      {children}
    </TinaCMSDataContext.Provider>
  );
});

export const useTinaCMSData = () => {
  const { relativePath } = useContext(TinaCMSDataContext);

  const { data } = useTina<PageQuery>({
    data: {
      page: {
        id: relativePath,
        title: "",
        path: "",
        _sys: {
          filename: "",
          basename: "",
          breadcrumbs: [""],
          path: "",
          relativePath: "",
          extension: "",
        },
      },
    },
    variables: { relativePath },
    query: PageDocument,
  });

  const enhancedTinaData = data.page?.content
    ? {
        ...data,
        page: {
          ...data.page,
          content: data.page?.content?.map((widget) => {
            if (widget?.__typename) {
              return widgetPreview[widget?.__typename](widget);
            }

            console.error("Missing preview", widget);

            return widget;
          }),
        },
      }
    : null;

  return enhancedTinaData;
};
