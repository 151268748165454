import { getPreviewLinkNodes } from "@joli-panini/gatsby-theme-joli-panini";

import type { GetPreview, WidgetShape } from "../types";

export const getPreview: GetPreview<"PageContentWidgetBannerText"> = (
  widget
) => {
  return {
    ...widget,
    content: {
      ast: widget.content,
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    linkNodes: <WidgetShape<"PageContentWidgetBannerText">["linkNodes"]>(
      getPreviewLinkNodes(widget.linkNodes)
    ),
  };
};
