import { getPreviewImageNode } from "@joli-panini/gatsby-theme-joli-panini";

import { GetPreview, WidgetShape } from "../types";

export const getPreview: GetPreview<"PageContentWidgetImageGrid"> = (
  widget
) => {
  return {
    ...widget,
    grid: widget.grid?.map((row) => {
      return {
        ...row,
        imageNodes: row?.imageNodes?.map((imageNode) => {
          return getPreviewImageNode<
            NonNullable<
              NonNullable<
                NonNullable<
                  WidgetShape<"PageContentWidgetImageGrid">["grid"]
                >[number]
              >["imageNodes"]
            >[number]
          >(imageNode);
        }),
      };
    }),
  };
};
