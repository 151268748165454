import { getPreviewLinkNodes } from "@joli-panini/gatsby-theme-joli-panini";

import type { GetPreview, WidgetShape } from "../types";

export const getPreview: GetPreview<"PageContentWidgetListing"> = (widget) => {
  return {
    ...widget,
    introduction: {
      ...widget.introduction,
      ast: widget.introduction,
    },
    listing: widget.listing?.map((listingItem) => {
      return {
        ...listingItem,
        content: {
          ast: listingItem?.content,
        },
      };
    }),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    linkNodes: <WidgetShape<"PageContentWidgetListing">["linkNodes"]>(
      getPreviewLinkNodes(widget.linkNodes)
    ),
  };
};
