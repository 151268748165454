import { getPreviewImageNode } from "@joli-panini/gatsby-theme-joli-panini";

import type { GetPreview, WidgetShape } from "../types";

export const getPreview: GetPreview<"PageContentWidgetBannerImage"> = (
  widget
) => {
  return {
    ...widget,
    image:
      getPreviewImageNode<WidgetShape<"PageContentWidgetBannerImage">>(
        widget
      )?.["image"],
  };
};
