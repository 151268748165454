import type { WidgetName } from "./types";
import { getPreview as PageContentWidgetAgenda } from "./WidgetAgenda/preview";
import { getPreview as PageContentWidgetAnchor } from "./WidgetAnchor/preview";
import { getPreview as PageContentWidgetBannerImage } from "./WidgetBannerImage/preview";
import { getPreview as PageContentWidgetBannerText } from "./WidgetBannerText/preview";
import { getPreview as PageContentWidgetContactForm } from "./WidgetContactForm/preview";
import { getPreview as PageContentWidgetCustomContent } from "./WidgetCustomContent/preview";
import { getPreview as PageContentWidgetHeroUnit } from "./WidgetHeroUnit/preview";
import { getPreview as PageContentWidgetImageGrid } from "./WidgetImageGrid/preview";
import { getPreview as PageContentWidgetListing } from "./WidgetListing/preview";

export const widgetPreview: Record<WidgetName, any> = {
  PageContentWidgetBannerImage,
  PageContentWidgetBannerText,
  PageContentWidgetContactForm,
  PageContentWidgetCustomContent,
  PageContentWidgetImageGrid,
  PageContentWidgetAnchor,
  PageContentWidgetHeroUnit,
  PageContentWidgetListing,
  PageContentWidgetAgenda,
};
