import type { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";

import { TinaCMSDataProvider } from "./src/components/PreviewProvider";

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<unknown>): React.ReactNode => {
  return (
    <TinaCMSDataProvider
      relativePath={props.pageContext.relativePath as string}
    >
      {element}
    </TinaCMSDataProvider>
  );
};
