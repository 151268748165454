import type { GetPreview } from "../types";

export const getPreview: GetPreview<"PageContentWidgetContactForm"> = (
  widget
) => {
  return {
    ...widget,
    content: {
      ast: widget.content,
    },
    successMessage: {
      ast: widget.successMessage,
    },
    errorMessage: {
      ast: widget.errorMessage,
    },
  };
};
