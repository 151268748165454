import {
  getMostReadable,
  getMostVisible,
  responsive,
} from "@joli-panini/gatsby-theme-joli-panini";
import { UIColors } from "@joli-panini/gatsby-theme-joli-panini/src/components/ThemeProvider/constants";
import { Theme } from "@theme-ui/core";

import arrowIcon from "../assets/arrow.svg";

export const HEADER_HEIGHT = responsive({
  xs: "62px",
  md: "84px",
});

const theme: Theme = {
  config: {
    useLocalStorage: false,
  },
  space: [
    "0px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "96px",
    "128px",
    "256px",
    "512px",
  ],
  fonts: {
    body: "Spartan, sans-serif",
    heading: "Spartan, sans-serif",
  },
  fontSizes: [12, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.25,
  },
  colors: {
    // layout
    text: "#1D1D1B",
    background: "#fff",
    backdrop: "#f1f1f1",
    header: "#e50101",
    footer: "#1a1a1b",
    // theme
    primary: "#e50101",
    secondary: "#e50101",
    accent: "#e50101",
    muted: "#e5e5e5",
    highlight: "#14213d",
    // ui
    success: "#2A8536",
    error: "#FF2E2E",
  },
  breakpoints: ["640px", "768px", "1024px", "1280px", "1536px"],
  sizes: {
    container: "100%",
  },
  radii: {
    maximum: "99999px",
    medium: "30px",
    default: "22px",
    small: "10px",
    none: 0,
  },
  shadows: {
    default: "0 6px 24px rgba(0,0,0,.1)",
    higher: "0 6px 36px rgba(0,0,0,.3)",
  },
  layout: {
    container: {
      maxWidth: responsive({
        xs: "100%",
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        xxl: "1536px",
      }),
      paddingLeft: responsive({ xs: 3, md: 4, xl: 5, xxl: 7 }),
      paddingRight: responsive({ xs: 3, md: 4, xl: 5, xxl: 7 }),
      width: "100%",
    },
  },
  links: {
    nav: {
      fontWeight: "body",
    },
  },
  images: {
    default: {
      borderRadius: "medium",
      clipPath: "content-box",
    },
    grid: {
      borderRadius: "small",
      clipPath: "content-box",
    },
  },
  // @ts-expect-error untyped prop
  boxes: {
    siteWrapper: {
      maxWidth: "1536px",
      margin: "0 auto",
      background: "background",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      boxShadow: "default",
      minHeight: "100vh",
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      boxShadow: "0 12px 12px -12px rgba(0,0,0,.15)",
      paddingTop: 3,
      paddingBottom: 3,
      zIndex: 2,
      backgroundColor: "header",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.header).toHexString(),
      position: "sticky",
      top: 0,
      height: HEADER_HEIGHT,
      display: "flex",
      alignItems: "center",
    },
    footer: {
      backgroundColor: "footer",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.footer, [
          UIColors.lightAlt.toHexString(),
          UIColors.darkAlt.toHexString(),
        ]).toHexString(),
      paddingTop: 4,
      paddingBottom: 4,
    },
    narrowContainer: {
      display: "grid",
      gridTemplateColumns: responsive({
        lg: "2fr 8fr 2fr",
      }),
      "&:before": {
        content: responsive({ lg: '""' }),
      },
      "&:after": {
        content: responsive({ lg: '""' }),
      },
    },
    primary: {
      backgroundColor: "muted",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.muted).toHexString(),
      padding: 5,
      borderRadius: "small",
    },
    secondary: {
      backgroundColor: "background",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "muted",
      boxShadow: "default",
      borderRadius: "small",
      padding: 5,
      height: "100%",
    },
    hero: {
      position: "relative",
      minHeight: responsive({
        xs: HEADER_HEIGHT[0] ? `calc(100vh - ${HEADER_HEIGHT[0]})` : undefined,
        sm: HEADER_HEIGHT[1] ? `calc(100vh - ${HEADER_HEIGHT[1]})` : undefined,
        md: HEADER_HEIGHT[2] ? `calc(100vh - ${HEADER_HEIGHT[2]})` : undefined,
        lg: HEADER_HEIGHT[3] ? `calc(100vh - ${HEADER_HEIGHT[3]})` : undefined,
        xl: HEADER_HEIGHT[4] ? `calc(100vh - ${HEADER_HEIGHT[4]})` : undefined,
        xxl: HEADER_HEIGHT[5] ? `calc(100vh - ${HEADER_HEIGHT[5]})` : undefined,
      }),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      borderWidth: "2px",
      borderStyle: "solid",
      textDecoration: "none",
      borderRadius: "small",
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 4,
      paddingRight: "24px",
      textTransform: "uppercase",
      letterSpacing: "2px",
      textAlign: "center",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderColor: (theme: Theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.accent,
        ]).toHexString(),
      backgroundColor: (theme: Theme) =>
        getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.accent,
        ]).toHexString(),
      color: (theme: Theme) =>
        getMostReadable(
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.accent,
          ]).toHexString()
        ).toHexString(),
      transition: "all .5s",
      transitionProperty: "color, border, background",
      cursor: "pointer",
      "&:hover, &:focus": {
        borderColor: (theme: Theme) =>
          getMostReadable(theme.rawColors?.background, [
            theme.rawColors?.background,
          ]).toHexString(),
        backgroundColor: (theme: Theme) =>
          getMostReadable(theme.rawColors?.background, [
            theme.rawColors?.background,
          ]).toHexString(),
        color: (theme: Theme) =>
          getMostReadable(
            getMostReadable(theme.rawColors?.background, [
              theme.rawColors?.background,
            ]).toHexString()
          ).toHexString(),
        textDecoration: "none",
      },
      "&:after": {
        content: '"→"',
        marginLeft: 2,
      },
    },
    reset: {
      border: "none",
      margin: 0,
      padding: 0,
      width: "auto",
      overflow: "visible",
      background: "transparent",
      color: "inherit",
      font: "inherit",
      lineHeight: "normal",
      cursor: "pointer",
      textAlign: "inherit",
      WebkitAppearance: "none",
    },
  },
  forms: {
    label: {
      fontWeight: "bold",
      paddingLeft: "1rem",
      textTransform: "uppercase",
    },
    input: {
      border: (theme: Theme) =>
        `2px solid ${getMostReadable(
          theme.rawColors?.background
        ).toHexString()}`,
      background: "transparent",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderRadius: "small",
      padding: ".75rem 1rem",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: responsive({ xs: 1, md: 2 }),
      textRendering: "geometricPrecision",
      transition: "all .5s",
      transitionProperty: "color, border, background",
      "&:not(:focus):not(:placeholder-shown):invalid": {
        borderColor: (theme: Theme) => {
          return getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.error,
          ]).toHexString();
        },
      },
      "&:not(:focus):not(:placeholder-shown):valid": {
        borderColor: (theme: Theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.success,
          ]).toHexString(),
      },
      "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover":
        {
          textFillColor: (theme: Theme) =>
            `${getMostReadable(
              theme.rawColors?.background
            ).toHexString()} !important`,
        },
      "&:focus": {
        outline: "none",
      },
    },
    textarea: {
      border: (theme: Theme) =>
        `2px solid ${getMostReadable(
          theme.rawColors?.background
        ).toHexString()}`,
      background: "transparent",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderRadius: "small",
      padding: ".75rem 1rem",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: responsive({ xs: 1, md: 2 }),
      textRendering: "geometricPrecision",
      resize: "none",
      transition: "all .5s",
      transitionProperty: "color, border, background",
      "&:not(:focus):not(:placeholder-shown):invalid": {
        borderColor: (theme: Theme) => {
          return getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.error,
          ]).toHexString();
        },
      },
      "&:not(:focus):not(:placeholder-shown):valid": {
        borderColor: (theme: Theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.success,
          ]).toHexString(),
      },
      "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover":
        {
          textFillColor: (theme: Theme) =>
            `${getMostReadable(
              theme.rawColors?.background
            ).toHexString()} !important`,
        },
      "&:focus": {
        outline: "none",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
      textRendering: "geometricPrecision",
      overflow: "hidden",
      overflowY: "scroll",
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
      textSizeAdjust: "100%",
      background: "backdrop",
      "& a": {
        color: "currentColor",
        textDecoration: "underline",
        "&:hover, &:focus": {
          textDecoration: "underline",
        },
      },
    },
    h1: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      marginTop: 0,
      marginBottom: 0,
    },
    h2: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      marginTop: 0,
      marginBottom: 0,
    },
    h3: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
      marginTop: 0,
      marginBottom: 0,
    },
    h4: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
      marginTop: 0,
      marginBottom: 0,
    },
    h5: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
      marginTop: 0,
      marginBottom: 0,
    },
    h6: {
      color: "currentColor",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    p: {
      color: "currentColor",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
      paddingTop: 0,
      marginBottom: "1.65em",
      breakInside: "avoid",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    a: {
      color: "currentColor",
      textDecoration: "underline",
      "&:hover, &:focus": {
        textDecoration: "underline",
      },
    },
    img: {
      maxWidth: "100%",
      display: "block",
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
      marginBottom: "2em",
      "&:last-child": {
        marginBottom: 0,
      },
      li: {
        position: "relative",
        paddingLeft: "3ex",
        marginBottom: ".5em",
        marginLeft: responsive({ xs: 2, md: 4 }),
        "&:last-child": {
          marginBottom: 0,
        },
        "&:before": {
          content: `url(${arrowIcon})`,
          position: "absolute",
          left: 0,
        },
      },
    },
    ol: {
      marginTop: "2em",
      marginBottom: "2em",
      "&:last-child": {
        marginBottom: 0,
      },
      li: {
        marginBottom: ".5em",
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    pre: {
      whiteSpace: "pre-wrap",
      margin: 0,
      marginTop: "2em",
      marginBottom: "2em",
      padding: responsive({ xs: 3, md: 4 }),
      backgroundColor: "muted",
      borderRadius: "small",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    blockquote: {
      borderLeftColor: "accent",
      borderLeftStyle: "solid",
      borderLeftWidth: "3px",
      marginTop: "2em",
      marginBottom: "2em",
      marginX: responsive({ xs: 2, sm: 4 }),
      padding: 0,
      paddingLeft: 4,
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "-4px",
        height: "2em",
        backgroundColor: "#fff",
        width: "5px",
        marginTop: "-2em",
      },
      "&:after": {
        color: "accent",
        content: '"“"',
        fontSize: "4em",
        fontStyle: "normal",
        fontFamily: 'Georgia, Times, "Times New Roman", serif',
        left: "-0.5em",
        lineHeight: "1",
        marginTop: "-0.5em",
        position: "absolute",
        textAlign: "center",
        top: "50%",
        width: "1em",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
};

export default theme;
