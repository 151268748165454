import {
  getPreviewImageNode,
  getPreviewLinkNodes,
} from "@joli-panini/gatsby-theme-joli-panini";

import type { GetPreview, WidgetShape } from "../types";

export const getPreview: GetPreview<"PageContentWidgetHeroUnit"> = (widget) => {
  return {
    ...widget,
    content: {
      ast: widget.content,
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    imageNode: <WidgetShape<"PageContentWidgetHeroUnit">["imageNode"]>(
      getPreviewImageNode(widget.imageNode, ["image", "imagePortrait"])
    ),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    linkNodes: <WidgetShape<"PageContentWidgetHeroUnit">["linkNodes"]>(
      getPreviewLinkNodes(widget.linkNodes)
    ),
  };
};
